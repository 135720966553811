import { Checkbox, Col, DatePicker, Form, Input, Select, TimePicker, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DragUploader from '../../../app/components/uploader/DragUploader'
import { ICollection } from '../../../app/models/Collection'
import { IMediaItem } from '../../../app/models/MediaItem'
import { IServiceType } from '../../../app/models/ServiceType'
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

interface IProps {
	collection: Partial<ICollection>;
	setCollection: (collection: Partial<ICollection>) => void;
	serviceTypes: IServiceType[];
	validateCallback?: (value: boolean) => void;
	showAttachments?: boolean;
}

const CollectionDetailsComponent: React.FC<IProps> = ({ serviceTypes, collection, setCollection, validateCallback, showAttachments = true }) => {
	const [service, _setService] = useState<IServiceType | undefined>(collection.serviceType);
	const [insurance, _setInsurance] = useState(collection?.insurance ?? false);
	const [comment, _setComment] = useState(collection.comment);
	const [attachments, _setAttachments] = useState<IMediaItem[] | undefined>(collection.attachments);
	const [selectedReadyDate, _setSelectedReadyDate] = useState<Date | undefined>(collection.readyDate);
	const [selectedReadyTime, _setSelectedReadyTime] = useState(collection.readyTime);
	const [selectedClosingTime, _setSelectedClosingTime] = useState(collection.closingTime);

	const setService = (value: IServiceType | undefined) => {
		_setService(value);
		setCollection({ ...collection, serviceType: value });
	}

	const setInsurance = (value: boolean) => {
		_setInsurance(value);
		setCollection({ ...collection, insurance: value });
	}

	const setComment = (value: string) => {
		_setComment(value);
		setCollection({ ...collection, comment: value });
	}

	const setAttachments = (value: IMediaItem[]) => {
		_setAttachments(value);
		setCollection({ ...collection, attachments: value });
	}

	const setSelectedReadyDate = (value: Date | undefined) => {
		_setSelectedReadyDate(value);
		setCollection({ ...collection, readyDate: value });
	}

	const setSelectedReadyTime = (value: string) => {
		_setSelectedReadyTime(value);
		setCollection({ ...collection, readyTime: value });
	}

	const setSelectedClosingTime = (value: string) => {
		_setSelectedClosingTime(value);
		setCollection({ ...collection, closingTime: value });
	}

	const validate = () => {
		if (validateCallback && service && selectedClosingTime && selectedReadyDate && selectedReadyTime) {
			validateCallback(true);
		}
	}

	useEffect(() => {
		validate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [service, insurance, comment, attachments, selectedClosingTime, selectedReadyDate, selectedReadyTime])

	return (
		<>
			<div id='CustomCollectionDetailsComponentContainer'>
				<Col sm={24} md={16} xl={8}>
					<Form
						layout={'vertical'}
					>
						<Form.Item label={'Service:'}>
							<Select
								virtual={false}
								placeholder='select a service'
								showSearch
								filterOption={(input, option) =>
									option?.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={(value) => setService(serviceTypes.find(x => x.id === value?.toString()))}
								defaultValue={collection.serviceType?.id}
								getPopupContainer={() => document.getElementById('CustomCollectionDetailsComponentContainer')!}
							>
								{serviceTypes?.map(serviceType => {
									return (
										<Select.Option
											key={serviceType.id}
											value={serviceType.id}
											title={serviceType.code + serviceType.displayName}
										>
											{`${serviceType.displayName} (${serviceType.code})`}
										</Select.Option>
									)
								})}
							</Select>
						</Form.Item>
						<Form.Item label={'Ready Date:'}>
							<DatePicker onChange={(value) => setSelectedReadyDate(value?.toDate())} defaultValue={moment(selectedReadyDate).toString() === 'Invalid date' ? undefined : moment(selectedReadyDate)} />
						</Form.Item>
						<Form.Item label={'Ready time:'}>
							<TimePicker
								format="HH:mm"
								showNow={false}
								value={moment(selectedReadyTime, "HH:mm").toString() === 'Invalid date' ? undefined : moment(selectedReadyTime, "HH:mm")}
								suffixIcon={<DownOutlined />}
								popupClassName={"timepicker"}
								minuteStep={15}
								onSelect={(value) => {
									const timeString = moment(value).format("HH:mm");
									setSelectedReadyTime(timeString)
								}} />
						</Form.Item>
						<Form.Item label={'Closing Time:'}>
							<TimePicker
								format="HH:mm"
								showNow={false}
								value={moment(selectedClosingTime, "HH:mm").toString() === 'Invalid date' ? undefined : moment(selectedClosingTime, "HH:mm")}
								suffixIcon={<DownOutlined />}
								popupClassName={"timepicker"}
								minuteStep={15}
								onSelect={(value) => {
									const timeString = moment(value).format("HH:mm");
									setSelectedClosingTime(timeString)
								}} />
						</Form.Item>
						<Form.Item label={'Optional insurance:'}>
							<Checkbox checked={insurance} onClick={(value) => setInsurance(!insurance)}> Check to add optional insurance </Checkbox>
							<br />
							<Typography.Text style={{ color: 'red' }}>
								Cum Laude Freight offers limited liability (see <Link to={'/tc'} target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>)
							</Typography.Text>
						</Form.Item>
						<Form.Item label={'Comment:'}>
							<Input autoComplete='off' defaultValue={collection.comment} maxLength={120} onBlur={value => { setComment(value.target.value) }} />
						</Form.Item>
						{showAttachments && <Form.Item label={'Attachments:'}>
							<DragUploader
								uploadUrl={`${process.env.REACT_APP_API_HOST}/api/media/upload/${collection.id}`}
								setUploadedFiles={setAttachments}
								uploadedFiles={attachments}
								collectionId={collection.id!}
							/>
						</Form.Item>}
					</Form>
				</Col>
			</div>
		</>
	)
}

export default CollectionDetailsComponent
