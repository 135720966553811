import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	pdf,
	Image
} from '@react-pdf/renderer';
import logo from '../../../assets/logo.png';
import JsBarcode from 'jsbarcode';
import { ICollection } from '../../models/Collection';
import { IAddress } from '../../models/Address';
import moment from 'moment';

const textToBase64Barcode = (waybill: string) => {
	var canvas = document.createElement('canvas');
	JsBarcode(canvas, waybill, { format: 'CODE128', width: 3 });
	return canvas.toDataURL('image/png');
};

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: '5%'
	},
	headerText: {
		fontSize: 12,
		color: 'grey'
	},
	section: {
		marginTop: '10px'
	},
	footer: {
		position: 'absolute',
		bottom: 30,
		left: 30
	},
	footerHeading: {
		fontSize: 12,
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	footerText: {
		fontSize: 12
	},
	logo: {
		width: '240px'
	},
	logos: {
		flexDirection: 'row',
		maxHeight: '90px'
	},
	barcode: {
		marginLeft: '50px',
		width: '180px'
	},
	row: {
		flexDirection: 'row'
	},
	column: {
		width: '50%'
	},
	columnThree: {
		width: '33%'
	},
	columnFive: {
		width: '20%'
	},
	column10: {
		width: '10%'
	},
	column90: {
		width: '90%'
	},
	textSmall: {
		fontSize: '12pt',
		maxHeight: '60px',
		lineHeight: '1.2'
	},
	text: {
		fontSize: '15pt',
		maxHeight: '60px',
		lineHeight: '1.2'
	},
	textHeavy: {
		fontSize: '15pt',
		maxHeight: '18px',
		lineHeight: '1.2',
		fontWeight: 900,
		fontFamily: 'Helvetica-Bold'
	},
	heading: {
		fontSize: '22pt',
		fontFamily: 'Helvetica-Bold',
		fontWeight: 'bold'
	},
	line: {
		padding: 0,
		margin: 0
	},
	pageNumber: {
		marginLeft: 450
	}
});

const convertAddressToString = (address: IAddress): string => {
	let result = address.addressLine1;
	if (address.addressLine2) {
		result = result + ` (${address.addressLine2})`;
	}

	result =
		result +
		`, ${address.city} (${address.postalCode}), ${address.country}`;

	return result;
};

const MyDocument = (collection: ICollection) => (
	<Document>
		{Array.from(
			{ length: Math.ceil(collection.contents.length / 10) || 1 },
			(_, i) => i + 1
		).map((it) => {
			return (
				<Page style={styles.body}>
					<View style={styles.logos}>
						<Image style={styles.logo} src={logo} />
						<Image
							style={styles.barcode}
							src={textToBase64Barcode(collection.waybill)}
						/>
					</View>
					<View style={styles.section}>
						<Text style={styles.headerText} fixed>
							P.O. Box: 2764 | Somerset West | Tel: 021 493 2819 |
							Cel: 083 438 4341
						</Text>
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text style={styles.text}>
									{moment(collection.readyDate).format(
										'D MMMM yyyy HH:mm'
									)}
								</Text>
								<View style={styles.section}>
									<Text style={styles.heading}>From</Text>
									<Text style={styles.textSmall}>
										{collection.sender.name}
									</Text>
									<Text style={styles.textSmall}>
										{collection.sender.contactPerson}
									</Text>
									<Text style={styles.textSmall}>
										{collection.sender.contactCell}
									</Text>
									<Text style={styles.textSmall}>
										{convertAddressToString(
											collection.sender
										)}
									</Text>
								</View>
							</View>
							<View style={styles.column}>
								<Text style={styles.text}>
									{collection.supplier?.name || ' '}
								</Text>
								<View style={styles.section}>
									<Text style={styles.heading}>To</Text>
									<Text style={styles.textSmall}>
										{collection.receiver.name}
									</Text>
									<Text style={styles.textSmall}>
										{collection.receiver.contactPerson}
									</Text>
									<Text style={styles.textSmall}>
										{collection.receiver.contactCell}
									</Text>
									<Text style={styles.textSmall}>
										{convertAddressToString(
											collection.receiver
										)}
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<View style={styles.columnThree}>
								<View style={styles.row}>
									<Text style={styles.textHeavy}>
										Service:
									</Text>
									<Text
										style={[
											styles.text,
											{ paddingLeft: '3px' }
										]}
									>
										{collection.serviceType.code}
									</Text>
								</View>
							</View>
							<View style={styles.columnThree}>
								<View style={styles.row}>
									<Text style={styles.textHeavy}>
										Insurance:
									</Text>
									<Text
										style={[
											styles.text,
											{ paddingLeft: '3px' }
										]}
									>
										{collection.insurance ? 'Yes' : 'No'}
									</Text>
								</View>
							</View>
							<View style={styles.columnThree}>
								<View style={styles.row}>
									<Text style={styles.textHeavy}>Value:</Text>
									<Text
										style={[
											styles.text,
											{ paddingLeft: '3px' }
										]}
									>
										________________
									</Text>
								</View>
							</View>
						</View>
						<View>
							<View style={styles.row}>
								<Text style={styles.textHeavy}>
									Collection Time Between:
								</Text>
								<Text
									style={[
										styles.text,
										{ paddingLeft: '3px' }
									]}
								>
									{`${collection.readyTime} and ${collection.closingTime}`}
								</Text>
							</View>
						</View>
					</View>
					<View>
						<Text style={[styles.line, styles.text]}>
							-------------------------------------------------------------------------------------------------------------
						</Text>
					</View>
					<View style={styles.row}>
						<View style={styles.column10}>
							<Text style={styles.textHeavy}>
								Note:
							</Text>
						</View>
						<View style={styles.column90}>
							<Text style={[styles.textSmall, { paddingLeft: '3px' }]}>
								{collection.comment}
							</Text>
						</View>
					</View>
					<View>
						<Text style={[styles.line, styles.text]}>
							-------------------------------------------------------------------------------------------------------------
						</Text>
					</View>
					<View>
						<View style={styles.row}>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Pieces</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Length</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Width</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>Height</Text>
							</View>
							<View style={styles.columnFive}>
								<Text style={styles.textHeavy}>
									Mass (kg's)
								</Text>
							</View>
						</View>
					</View>
					<View style={{ maxHeight: '190px' }}>
						{Array.from(
							{
								length: 10
							},
							(_, i) => i + 1
						).map((x, index) => {
							return (
								<View>
									<View
										style={[
											styles.row,
											{
												backgroundColor:
													index % 2 === 0
														? 'white'
														: 'lightgray'
											}
										]}
									>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													collection.contents[
														it * 10 - 10 + index
													]?.pieces
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													collection.contents[
														it * 10 - 10 + index
													]?.length
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													collection.contents[
														it * 10 - 10 + index
													]?.width
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{
													collection.contents[
														it * 10 - 10 + index
													]?.height
												}
											</Text>
										</View>
										<View style={styles.columnFive}>
											<Text style={styles.text}>
												{(
													collection.contents[
														it * 10 - 10 + index
													]?.weight *
													collection.contents[
														it * 10 - 10 + index
													]?.pieces
												).toString() === 'NaN'
													? ' '
													: collection.contents[
															it * 10 - 10 + index
													  ]?.weight *
													  collection.contents[
															it * 10 - 10 + index
													  ]?.pieces}
											</Text>
										</View>
									</View>
								</View>
							);
						})}
					</View>
					<View>
						<Text style={[styles.line, styles.text]}>
							-------------------------------------------------------------------------------------------------------------
						</Text>
					</View>
					<View>
						<View style={styles.row}>
							<View style={styles.columnThree}>
								<Text style={styles.textHeavy}>Sender:</Text>
								<Text style={styles.text}>
									Date:_______________
								</Text>
								<Text style={styles.text}>
									Time:_______________
								</Text>
								<Text style={styles.text}>
									Name:______________
								</Text>
							</View>
							<View style={styles.columnThree}>
								<Text style={styles.textHeavy}>
									Received by Courier:
								</Text>
								<Text style={styles.text}>
									Date:_______________
								</Text>
								<Text style={styles.text}>
									Time:_______________
								</Text>
								<Text style={styles.text}>
									Name:______________
								</Text>
							</View>
							<View style={styles.columnThree}>
								<Text style={styles.textHeavy}>
									Receivers Details:
								</Text>
								<Text style={styles.text}>
									Date:_______________
								</Text>
								<Text style={styles.text}>
									Time:_______________
								</Text>
								<Text style={styles.text}>
									Name:______________
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<Text style={styles.footerHeading}>Please note:</Text>
						<Text style={styles.footerText}>
							*Should the sizes differ to what was given the cost
							on the waybill will differ
						</Text>
						<Text style={styles.footerText}>
							*Cum Laude Freight Management is subject to limited
							liability of the carrier.
						</Text>
						<Text style={styles.footerText}>
							*Please see our Terms and Conditions at
							https://www.cumlaudefreight.co.za/tc.
						</Text>
						<View style={styles.pageNumber}>
							<Text
								style={styles.footerText}
								render={({ pageNumber, totalPages }) =>
									`Page ${pageNumber} / ${totalPages}`
								}
								fixed
							/>
						</View>
					</View>
				</Page>
			);
		})}
	</Document>
);

export const download = async (collection: ICollection): Promise<void> => {
	var FileSaver = require('file-saver');
	const blob = await pdf(<MyDocument {...collection} />).toBlob();
	FileSaver.saveAs(blob, `${collection.waybill}.pdf`);
};

export const open = async (collection: ICollection): Promise<void> => {
	const blob = await pdf(<MyDocument {...collection} />).toBlob();
	const url = URL.createObjectURL(blob);
	window.open(url);
};

export const replace = async (collection: ICollection): Promise<void> => {
	const blob = await pdf(<MyDocument {...collection} />).toBlob();
	const url = URL.createObjectURL(blob);
	window.location.replace(url);
};

export const getBlob = async (collection: ICollection): Promise<Blob> => {
	const blob = await pdf(<MyDocument {...collection} />).toBlob();
	return blob;
};
